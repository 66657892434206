@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
    /* fonts */
    --caption-m-12: Roboto;

    /* font sizes */
    --body-sb-14-size: 14px;
    --font-size-base: 16px;
    --caption-m-12-size: 12px;
    --heading-m-20-size: 20px;
  
    /* Colors */
    --icon-white: #fff;
    --color-lavender: #dde9ff;
    --color-dimgray-100: #707070;
    --text-tertiary: #6a6a6b;
    --text-hint: #818182;
    --text-primary: #1a1a1c;
    --text-primary1: #0d162f;
    --surface-primary: #f9f9f9;
    --stroke-primary: #e8e8e8;
    --surface-brand-secondary: #ff7d34;
    --stroke-brand-primary: #009872;
    --color-slategray: #6a707e;
    --sidenav-menu-active-surface: #56bb6b;
    --color-powderblue: #c5e7dc;
    --stroke-tertiary: #a3a3a4;
    --text-hint-2: #d1d1d2;
  
    /* Spacing */
    --new-group-16: 16px;
    --new-group-8: 8px;
    --new-group-6: 6px;
  
    /* Gaps */
    --gap-5xs: 8px;
    --gap-base: 16px;
    --gap-7xs: 6px;
    --gap-xl: 20px;
    --gap-xs: 12px;
    --gap-0: 0px;
    --gap-3xs: 10px;
  
    /* Paddings */
    --padding-3xl: 22px;
    --padding-5xl: 24px;
    --padding-204xl: 223px;
    --padding-5xs: 8px;
    --padding-6xs: 7px;
    --padding-49xl: 68px;
    --padding-base: 16px;
    --padding-xl: 20px;
    --padding-6xs-5: 6.5px;
    --padding-lgi: 19px;
    --padding-sm: 14px;
    --padding-xs: 12px;
    --padding-mini: 15px;
    --padding-101xl: 120px;
    --padding-7xs: 6px;
    --padding-150xl: 169px;
    --padding-lg: 18px;
    --padding-mid: 17px;
    --padding-3xs: 10px;
    --padding-2xs: 11px;
    --padding-9xs: 4px;
  
    /* Border radiuses */
    --br-xs: 12px;
    --br-7xs: 6px;
    --br-5xs: 8px;
    --br-9xs: 4px;
    --br-mini: 15px;
}
