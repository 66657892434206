.arrow-back-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  /* padding: 28.8px 0px 28.8px 28.8px; */
  cursor: pointer;
}

.dashboard {
  text-decoration: none;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  color: inherit;
  display: inline-block;
}

.back-button-wrapper {
  width: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4%;
}

.person-24dp-5f6368-fill0-wght3-icon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.this-week {
  height: 16px;
  width: 56px;
  position: relative;
  font-weight: 500;
  display: inline-block;
}

.keyboard-arrow-down-24dp-5f636-icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.person-24dp-5f6368-fill0-wght3-parent {
  border-radius: var(--br-9xs);
  border: 1px solid var(--stroke-primary);
  box-sizing: border-box;
  height: 40px;
  padding: var(--padding-xs) var(--padding-5xs);
  gap: var(--gap-7xs);
}

.person-24dp-5f6368-fill0-wght3-parent,
.week-filter-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.calendar-month-24dp-5f6368-fil-icon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.this-week1 {
  height: 16px;
  width: 65px;
  position: relative;
  font-weight: 500;
  display: inline-block;
}

.keyboard-arrow-down-24dp-5f636-icon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.calendar-month-24dp-5f6368-fil-parent {
  border-radius: var(--br-9xs);
  border: 1px solid var(--stroke-primary);
  box-sizing: border-box;
  height: 40px;
  padding: var(--padding-xs) var(--padding-5xs);
  gap: var(--gap-7xs);
}

.calendar-month-24dp-5f6368-fil-parent,
.week-filter-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.this-week2 {
  text-decoration: none;
  height: 16px;
  position: relative;
  font-weight: 500;
  color: inherit;
  display: inline-block;
}

.current-week {
  height: 30px;
  width: 30px;
  border-radius: var(--br-mini);
  background-color: var(--surface-brand-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-9xs);
  box-sizing: border-box;
}

.this-week3 {
  text-decoration: none;
  height: 16px;
  position: relative;
  font-weight: 500;
  color: var(--text-primary1);
  display: inline-block;
}

.week-selection {
  justify-content: 'flex-end';
  gap: var(--gap-7xs);
  color: var(--icon-white);
}

.header,
.week-filter,
.week-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.week-filter {
  justify-content: flex-end;
  gap: var(--gap-xs);
  font-size: var(--body-sb-14-size);
}

.header {
  position: absolute;
  top: 0;
  left: 140px;
  background-color: var(--icon-white);
  border-bottom: 1px solid var(--stroke-primary);
  box-sizing: border-box;
  width: 1780px;
  height: 72px;
  justify-content: space-between;
  padding: var(--padding-3xs) var(--padding-5xl);
  text-align: left;
  font-size: var(--heading-m-20-size);
  color: var(--text-primary);
  font-family: var(--caption-m-12);
}