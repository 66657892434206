body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 0px; /* Set width to 0 to make it invisible */
   /* background: transparent; Ensure no background */
}

/* Hide scrollbar for Firefox */
.scrollable-element {
  scrollbar-width: none;
  /* -ms-overflow-style: none; Hide scrollbar in IE */
}

/* You can add additional styles to your scrollable container as needed */
.scrollable-element {
  max-height: 500px;
  overflow-y: scroll; /* Enables scrolling without showing scrollbar */
}


.recharts-bar-rectangle:hover { opacity: 1 !important; }

.communication-sequence-table {
  width: 100%;
  height:100%;
  border-collapse: collapse; /* Ensures borders don't double up */
  border-style: solid;
  border-color: #E8E8E8;
  border-width: 0px 0 1px 0; /* Top and Bottom only */
  border-radius: 8px; /* Rounded corners */

}
.communication-sequence-table th{
  border-style: solid;
  border-color: #E8E8E8;
  border-width: 0px 1px 1px 1px; /* Top and Bottom only */
  padding: 15px;
  text-align: center;
}

.communication-sequence-table th:first-child{
  border-color: #E8E8E8;
  border-width: 0px 1px 1px 0; /* Top and Bottom only */

}

.communication-sequence-table th:last-child{
  border-color: #E8E8E8;
  border-width: 0px 1px 0px 1px; /* Top and Bottom only */

}

.communication-sequence-table td {
  border: 1px solid #E8E8E8; /* Inner cell borders */
  text-align: center;
}

.communication-sequence-table td:nth-child(6) {
  border-width: 1px 0px 1px 0; /* Top and Bottom only */
  border-color:#E8E8E8;
  text-align: center;
}

.communication-sequence-table td:first-child{
  border-color: #E8E8E8;
  border-width: 1px 1px 1px 0px; /* Top and Bottom only */
}
 
.measure-plan-table th{
  border-style: solid;
  border-color: #E8E8E8;
  border-width: 1px 1px 1px 1px; /* Top and Bottom only */
  padding: 15px;
  text-align: center;
  background-color: #FFF5ED;
  width:50%;
  border-radius: 8px 8px 0 0;
  color:#FF7D34;
}

.measure-plan-table{

  width: 100%;
  height:100%;
  border-collapse: collapse; /* Ensures borders don't double up */
  border-style: solid;
  border-color: #E8E8E8;
  border-width: 0px 0 1px 0; /* Top and Bottom only */
  border-radius: 8px; /* Rounded corners */
}

.measure-plan-table td {
  border: 1px solid #E8E8E8; /* Inner cell borders */
  padding: 15px;
  text-align: center;
}

.home-table,.approval-table {
  width: 100%;
  /* border: 1px solid #E0E0E0; */
  border-collapse: collapse;
  border-radius: 8px;

}

.home-table th, .home-table tr ,
.approval-table th,.approval-table tr{
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #E0E0E0;
}

.home-table td,
.approval-table td{
  padding:5px 10px;
}
/* .home-table tr:last-child ,
.approval-table tr:last-child{
border-bottom: none;
} */

.home-table th{
  background-color: #F3F1FC;
  /* border-radius: 8px 0 0 0 ; */
}

.home-table th:last-child{
  background-color: #F3F1FC;
  border-radius: 0 8px 0 0 ;
}


.approval-table th{
  background-color: #EBF6F0;
  border-radius:8px 0 0 0 ;
}

.approval-table th:last-child{
  background-color: #EBF6F0;
  border-radius: 0 8px 0 0 ;
}

.score-cell {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button-group {
  display: flex;
  justify-content:center;
  gap: 0px;
  width: 100%;
  padding: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust as needed */
}

.buyer-intent-table{
  width: 100%;
  height:100%;
  border-collapse: collapse; /* Ensures borders don't double up */
  border-style: solid;
  border-color: #E8E8E8;
  border-width: 0px 0 1px 0; /* Top and Bottom only */
  border-radius: 8px; /* Rounded corners */
  font-family: "roboto";
}

.buyer-intent-table th{
font-weight: 500;
  padding: 10px;
  text-align: left;
  background-color: #F3F1FC;
}

.buyer-intent-table th:first-child{
  border-radius: 8px 0 0 0 ;
}

.buyer-intent-table th:last-child{
  border-radius: 0 8px 0 0 ;
}

.buyer-intent-table td {
  border-bottom: 1px solid #E8E8E8; /* Inner cell borders */
  padding: 10px;
  text-align: left;
  color:#6A6A6B;
  font-size: 16px;
}

@media (max-width:  1536px) {
  .buyer-intent-table td {
    font-size: 12px;
    padding: 8px;
  }
  .buyer-intent-table th{

      padding: 8px;
      font-size: 12px;
    }
}