.buddy-1-icon {
  height: 36px;
  width: 36px;
  object-fit: cover;
  padding: 20px 0px 20px 16px;
}


.AttachmentIcon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.attachmentButton {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  justify-content: center;
  /* padding: var(--padding-11xs); */
  box-sizing: border-box;
  cursor: pointer;
}

.project-aws {
  text-decoration: none;
  position: relative;
  font-weight: 'bold';
  color: inherit;
  display: inline-block;
  min-width: 82px;
  font-size: 20px;
}

.layout,
.project-a-w-s-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs-5) 0 0;
}

.layout {
  align-self: stretch;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  border-top: 1px solid var(--color-powderblue);
  border-right: 1px solid var(--color-powderblue);
  border-left: 1px solid var(--color-powderblue);
  flex-direction: row;
  padding: 20px;
  gap: var(--gap-3xs);
  font-size: var(--heading-m-20-size);
}

.hi-roney {
  margin: 0;
}

.hi-roney-choose-container {
  flex: 1;
  position: relative;
  line-height: 140%;
  display: inline-block;
  max-width: 100%;
}

.clientele-options {
  margin-top: -442px;
  width: 600px;
  border-radius: var(--br-xs) var(--br-xs) var(--br-xs) 0;
  background-color: var(--icon-white);
  border: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-lgi);
  flex-shrink: 0;
  max-width: 100%;
}

.geography {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}

.select-the-geographic {
  position: relative;
  font-size: var(--body-sb-14-size);
  color: var(--text-tertiary);
  font-family: "roboto";
  line-height: 1.5;
  color: #0D162F;
  font-size: 16px;
}

.geography-selector {
  align-self: stretch;
  gap: var(--gap-5xs);
}

.geographical-locations,
.geography-selector,
.location-and-industry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.geographical-locations {
  align-self: stretch;
}

.location-and-industry {
  border-radius: var(--br-xs) var(--br-xs) var(--br-xs) 0;
  background-color: var(--icon-white);
  border: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  padding: var(--padding-lg) var(--padding-lgi);
  min-height: 83px;
  flex-shrink: 0;
  max-width: 100%;
}

.us-east-coast {
  align-self: stretch;
  position: relative;
  line-height: 140%;
  font-weight: 500;
}

.us-east-coast-uswest-coast-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.location-display,
.location-display-inner {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.location-display-inner {
  align-self: stretch;
  border-radius: var(--br-xs) var(--br-xs) 0 var(--br-xs);
  background-color: var(--stroke-brand-primary);
  border: 1px solid var(--color-powderblue);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) var(--padding-mid);
}

.location-display {
  width: 253px;
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
}

.industry-summary,
.location-summary {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.location-summary {
  align-self: stretch;
  height: 46px;
  align-items: flex-end;
  justify-content: center;
  color: var(--icon-white);
}

.industry-summary {
  width: 487px;
  border-radius: var(--br-xs) var(--br-xs) var(--br-xs) 0;
  background-color: var(--icon-white);
  border: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-mid) var(--padding-lg) var(--padding-xl);
  gap: var(--gap-5xs);
  min-height: 83px;
  max-width: 100%;
}

.banking-financial-services {
  position: relative;
  line-height: 140%;
  font-weight: 500;
}

.industry-value-container,
.industry-value-parent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
}

.industry-value-parent {
  flex: 1;
  border-radius: var(--br-xs) var(--br-xs) 0 var(--br-xs);
  background-color: var(--stroke-brand-primary);
  border: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-2xs);
}

.industry-value-container {
  width: 600px;
  flex-direction: row;
}

.company-and-contact,
.industry-display {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 90%;
  word-break : keep-all;
  text-wrap: wrap;
}

.industry-display {
  align-self: stretch;
  align-items: flex-end;
  justify-content: center;
  color: var(--icon-white);
}

.company-and-contact {
  border-radius: var(--br-xs) var(--br-xs) var(--br-xs) 0;
  background-color: var(--icon-white);
  border: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  gap: var(--gap-5xs);
  overflow-wrap : anywhere
}




.messages-inner-box {
  border-radius: var(--br-xs) var(--br-xs) 0 var(--br-xs);
  background-color: var(--stroke-brand-primary);
  border: 1px solid var(--color-powderblue);
  padding: var(--padding-3xs) var(--padding-2xs);
  max-width: 70%;
  min-width: "fit-content";
  font-size: var(--font-size-base);
  line-height: 140%;
  font-weight: 500;
  font-family: var(--caption-m-12);
  color: var(--icon-white);
  text-align: left;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  white-space: pre-wrap;
  word-break: keep-all;
}

.message-outer-box {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.contact-criteria-container,
.refinement-options {
  /* border: 1px solid var(--color-powderblue); */

  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.contact-criteria-container {
  border: 1px solid var(--color-powderblue);

  border-radius: var(--br-xs) var(--br-xs) var(--br-xs) 0;
  background-color: var(--icon-white);
  padding: var(--padding-lg) var(--padding-mid) var(--padding-lg) var(--padding-xl);
  gap: var(--gap-5xs);
  min-height: 99px;

}

.refinement-options {
  align-self: stretch;
  height: 100%;
  /* background: blue; */
  overflow: auto;
  padding: 20px;
  gap: var(--gap-xl);
  color: var(--text-primary1);


}
/* Scrollbar styles specific to .refinement-options */
.refinement-options::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.refinement-options::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #afafaf; */
  border-radius: 10px;
}

/* Handle */
.refinement-options::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

/* Handle on hover */
.refinement-options::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}


/* @media (min-resolution: 120dpi) {
  .refinement-options {
    height: 69vh;
  }
}

@media (min-resolution: 144dpi) {
  .refinement-options {
    height: 75vh;
  }
} */

.send-a-message {
  position: relative;
  line-height: 140%;
  font-weight: 500;
  display: inline-block;
  min-width: 120px;
}

.button2 {
  height: 32px;
  width: 83px;
}

.button4 {
  position: relative;
  font-weight: 600;
}

.close-icon {
  height: 10px;
  width: 10px;
  position: relative;
}

.visibility-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  display: none;
}

.button3 {
  border-radius: var(--br-9xs);
  background-color: var(--icon-white);
  border: 1px solid var(--stroke-brand-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-sm);
  gap: var(--gap-xs);
}

.button10,
.button8 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 68px;
}

.button10 {
  min-width: 75px;
}

.button9 {
  border-radius: var(--br-9xs);
  background-color: var(--icon-white);
  border: 1px solid var(--stroke-tertiary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-sm);
  gap: var(--gap-xs);
  color: var(--text-hint);
}

.button-parent,
.button13 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.button13 {
  height: 32px;
  border-radius: var(--br-9xs);
  background-color: var(--icon-white);
  border: 1px solid var(--stroke-tertiary);
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-sm);
  gap: var(--gap-xs);
  color: var(--text-hint);
}

.button-parent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 var(--padding-101xl) 0 0;
  gap: 12px 9.5px;
  max-width: 100%;
}

.attachment-24dp-5f6368-fill0-w-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.attachment-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.button-container,
.message-actions {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.button-container {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-0);
  row-gap: 20px;
  font-size: var(--body-sb-14-size);
  color: var(--stroke-brand-primary);
}

.message-actions {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--icon-white);
  border: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  flex-direction: row;
  /* align-items: flex-end; */
  /* padding: var(--padding-xs) var(--padding-mini) var(--padding-xs) var(--padding-lgi); */
  padding: 8px 12px;
}

.keyboard-dropdown,
.message-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.keyboard-dropdown {
  border-radius: var(--br-7xs);
  background-color: var(--stroke-brand-primary);
  align-items: center;
  padding: 12px;
}

.message-content {
  align-self: stretch;
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-xs);
  max-width: 100%;
}

.message-input {
  font-family: "Roboto";
  font-size: "16px";
  font-weight: 500;
}

.message-input:focus,
.message-input:active {
  border: none;
  /* Removes border */
  outline: none;
  /* Removes the default focus outline */
}

.message-input::placeholder {
  font-weight: 400;
  color: #D1D1D2;
  font-family: "Roboto";
  font-size: "16px";
  /* padding: 0px 6px; */

}

.market-plan-layout1,
.send-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.send-message-container {
  align-self: stretch;
  /* height: 179px; */
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  border-right: 1px solid var(--color-powderblue);
  border-bottom: 1px solid var(--color-powderblue);
  border-left: 1px solid var(--color-powderblue);
  box-sizing: border-box;
  padding: var(--padding-base) var(--padding-lgi) var(--padding-sm);
  color: var(--text-hint-2);
}

.market-plan-layout1 {

  /* background: red; */
  background: linear-gradient(180deg,
      rgba(86, 187, 107, 0.05),
      rgba(0, 152, 114, 0.05));
  /* width: 854px; */
  border-radius: 10px;
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--text-primary);
  font-family: var(--caption-m-12);
  margin-top: 10px;
  margin-left: 10px;
}


/* .market-plan-layout1 > .layout {
  height: 20%;
}

.market-plan-layout1 > .refinement-options {
  height: 10%;
}

.market-plan-layout1 > .send-message-container {
  height: 30%;
} */

@media screen and (max-width: 800px) {
  .button-container {
    flex-wrap: wrap;
  }

  .message-actions {
    min-height: auto;
  }

  .message-content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 675px) {
  .message-actions {
    min-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .project-aws {
    font-size: var(--font-size-base);
  }

  .layout {
    flex-wrap: wrap;
  }
}


.button-custom-selected {
  background: #fff;
  justify-content: flex-start;
  height: 32px;
  width: auto;
  border: 1px solid #009872;
  color: #009872;
  text-transform: capitalize;
  font-size: 14px;
  display: flex;
  align-items: center;
  /* Ensures text and icon are aligned properly */
}


.button-custom {
  background: #fff;
  justify-content: flex-start;
  height: 32px;
  width: auto;
  border: 1px solid #A3A3A4;
  color: #818182;
  text-transform: capitalize;
  font-size: 14px;
  display: flex;
  align-items: center;
  /* Ensures text and icon are aligned properly */
}


.chat-text-area{
  width: "100%";
  border: "none";
  outline: "none";
  overflow-y: "auto";
  overflow-x: "hidden";
  resize: "none";
  display: "flex";
  align-items: "center";
  line-height: "1.5";
  margin-left: "6px";
  font-size:"16px"
}