.backgroundIcon,
.piqualAiLoginPage {
  margin: 0 !important;
  bottom: 0;
  max-height: 100%;
}
.backgroundIcon {
  height: 100%;
  width: 1951px;
  position: absolute;
  top: 0;
  right: -31px;
}
.piqualAiLoginPage {
  right: 0;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 3;
}
.frameChild,
.logoIcon,
.piqualAiLoginPage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.frameChild {
  z-index: 1;
}
.logoIcon {
  margin: 0 !important;
  right: 0;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.beta,
.betaLogoChild {
  position: absolute;
  top: 0.8px;
  left: 0;
  width: 52.8px;
  height: 15.2px;
  z-index: 1;
}
.beta {
  text-decoration: none;
  top: 0;
  left: 6.5px;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  width: 38px;
  height: 15.7px;
  min-width: 38px;
  z-index: 2;
}
.betaLogo {
  height: 16px;
  width: 52.8px;
  position: relative;
}
.logoParent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 38.4px 1px 1.6px;
  position: relative;
  z-index: 2;
}
.logoIcon1,
.topContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.logoIcon1 {
  margin: 0 !important;
  right: 0;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 4;
}
.topContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 26px 17px;
  box-sizing: border-box;
}
.vectorParent {
  height: 108px;
  width: 260px;
  position: relative;
}
.letsGetStarted {
  margin: 0;
  height: 42px;
  position: relative;
  color: var(--text-primary);
  text-align: left;
  display: inline-block;
  flex-shrink: 0;
}
.interactiveInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.inputField1 {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  font-family: var(--heading-r-24);
  font-size: var(--font-size-sm);
  color: var(--color-darkgray);
}
.inputComponents,
.inputField {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.inputField {
  align-items: flex-start;
  gap: var(--gap-base);
}
.inputComponents {
  align-items: center;
  gap: 80px;
}
.forgotPassword {
  position: relative;
  font-size: 20px;
  font-family: var(--heading-r-24);
  color: var(--text-tertiary);
  text-align: left;
  font-family:Roboto;
  cursor: pointer;
}
.forgotPasswordWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.loginWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.button {
  align-self: stretch;
  height: var(--values-48);
}
.inputComponentsParent {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}
.frameParent,
.inputArea {
  align-self: stretch;
  max-width: 100%;
}
.inputArea {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 36px;
  box-sizing: border-box;
}
.frameParent {
  border-radius: 12px;
  background-color: var(--surface-overall-background);
  border: 1px solid var(--stroke-primary);
  flex-direction: column;
  padding: 52px 165px;
  gap: 80.1px;
  z-index: 4;
}
.frameParent,
.loginVariation5,
.mainContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.mainContent {
  width: 845px;
  flex-direction: column;
  padding: var(--padding-121xl) 0 0;
  max-width: 100%;
}
.loginVariation5 {
  width: 100%;
  position: relative;
  background-color: var(--surface-overall-background);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-21xl) var(--padding-121xl);
  gap: 238px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 13px;
  color: var(--surface-overall-background);
  font-family: var(--font-montserrat);
}

@media (max-width: 1024px){ 
  .loginVariation5 {
    height: 10%;
  
    
  }
  .vectorParent {
    height: 18px;
    width: 260px;
    right: 30px;
    position: relative;
  }
  .mainContent {
    width: 745px;
    margin-top: 6%;
    padding: 0% !important;
  }
  .frameParent {
        padding: 40px 40px;
        margin-left: -25%;
    z-index: 4;
    gap: 20.1px;
  }
  .inputComponents{
    gap:20px;
  }
  .inputComponentsParent {
    margin-left: 15%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    max-width: 70%;
  }
  .button{
    width: 98%;
  }
  .forgotPassword {
    position: relative;
    font-size: 14px;
    font-family: var(--heading-r-24);
    color: var(--text-tertiary);
    text-align: left;
  }
  
}

@media screen and (max-width: 1366px) {
  .loginVariation5 {
    height: 100%;
  
    
  }
  .vectorParent {
    height: 58px;
    width: 260px;
    right: 30px;
    position: relative;
  }
  .mainContent {
    width: 745px;
    margin-top: 6%;
    padding: 0% !important;
  }
  .frameParent {
        padding: 40px 40px;
        margin-left: -25%;
    z-index: 4;
    gap: 20.1px;
  }
  .inputComponents{
    gap:20px;
  }
  .inputComponentsParent {
    margin-left: 15%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    max-width: 70%;
  }
  .button{
    width: 98%;
  }
  .forgotPassword {
    position: relative;
    font-size: 14px;
    font-family: var(--heading-r-24);
    color: var(--text-tertiary);
    text-align: left;
  }

}
@media (min-resolution: 120dpi){

}  